export const getDefaultPieFormatting = (
    title: string | null,
    data: any[],
    decimals = 0,
    otherSettings: Highcharts.Options = {},
    missing = 0
) => {
    if (missing > 0) {
        data.push({
            name: 'Missing',
            y: missing,
            color: 'gray',
        })
    }

    return {
        chart: {
            plotShadow: false,
            type: 'pie'
        },
        tooltip: {
            pointFormat: `<b>{point.y:.${decimals}f} ({point.percentage:.1f}%)</b>`
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: `<b>{point.name}</b>: {point.y:.${decimals}f} ({point.percentage:.1f}%)`
                }
            }
        },
        title: {
            text: title ?? '',
            align: 'left',
        },
        series: [
            {
                colorByPoint: true,
                data,
            },
        ],
        ...otherSettings
    }
}