import {IBaselineSubmissionProps, IFinaleSubmissionProps} from './resources/Submission';
import {businessScaledTotalCalculation} from './total-calculations/business-scaled-total-calculation';
import {businessScaledChartCalculation} from './chart-calculations/business-scaled-chart-calculation';
import {successFactorsChartCalculation} from './chart-calculations/success-factors-chart-calculation';
import {contributionProgramChartCalculation} from './chart-calculations/contribution-program-chart-calculation';
import {failureFactorsChartCalculation} from './chart-calculations/failure-factors-chart-calculation';
import {increasedTurnoverChartCalculation} from './chart-calculations/increased-turnover-chart-calculation';
import {levelIncreasedChartCalculation} from './chart-calculations/level-increased-chart-calculation';
import {increasedEmployeesTotalCalculation} from './total-calculations/increased-employees-total-calculation';
import {womenEmployedChartCalculation} from './chart-calculations/women-employed-chart-calculation';
import {termContractChartCalculation} from './chart-calculations/term-contract-chart-calculation';
import {typeContractChartCalculation} from './chart-calculations/type-contract-chart-calculation';
import {participationEventsChartCalculation} from './chart-calculations/participation-events-chart-calculation';
import {levelEventsChartCalculation} from './chart-calculations/level-events-chart-calculation';
import {TYPE_COLUMN_TOTAL, TYPE_COLUMN_HIGH_CHART, IConfigSchema} from '../resources/config-schema';

export const configSchema: IConfigSchema<IBaselineSubmissionProps, IFinaleSubmissionProps> = [
    {
        titleSection: {
            title: 'Business evolution'
        },
        rows: [
            {
                columns: [
                    {
                        title: '% of entrepreneurs who scaled the business',
                        typeChart: TYPE_COLUMN_TOTAL,
                        transformHandler: businessScaledTotalCalculation,
                    }
                ]
            },
            {
                columns: [
                    {
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: businessScaledChartCalculation
                    },
                ]
            },
            {
                columns: [
                    {
                        title: 'Success factors',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: successFactorsChartCalculation
                    }
                ]
            },
            {
                columns: [
                    {
                        title: 'Contribution of the program',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: contributionProgramChartCalculation
                    },
                    {
                        title: 'Failure factors',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: failureFactorsChartCalculation
                    }
                ]
            }
        ]
    },
    {
        titleSection: {
            title: 'Increase in Turnover'
        },
        rows: [
            {
                columns: [
                    {
                        title: '% of entrepreneurs who increased the turnover',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: increasedTurnoverChartCalculation
                    },
                    {
                        title: 'Level of increased turnover',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: levelIncreasedChartCalculation
                    }
                ]
            },
        ]
    },
    {
        titleSection: {
            title: 'Increase in number of employees'
        },
        rows: [
            {
                columns: [
                    {
                        title: '% of entrepreneurs who increased the number of employees',
                        typeChart: TYPE_COLUMN_TOTAL,
                        transformHandler: increasedEmployeesTotalCalculation
                    },
                ]
            },
            {
                columns: [
                    {
                        title: '% women employed before and after',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: womenEmployedChartCalculation
                    },
                ]
            },
            {
                columns: [
                    {
                        title: '% term of contract before and after',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: termContractChartCalculation
                    },
                    {
                        title: '% type of contract before and after',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: typeContractChartCalculation
                    },
                ]
            },
        ]
    },
    {
        titleSection: {
            title: 'Business connections'
        },
        rows: [
            {
                columns: [
                    {
                        title: '% participation to the acceleration events',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: participationEventsChartCalculation
                    },
                    {
                        title: 'Level of utility of participating in events',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: levelEventsChartCalculation
                    },
                ]
            },
        ]
    },
]