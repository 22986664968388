import React from 'react';
import getValidSubmissions from '../helpers/getValidSubmissions';
import {configSchema} from './configSchema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from './resources/Submission';
import {ChartsContainer} from '../Common/ChartsContainer';

interface IImpactProps {
    final: any[];
    baseline: any[];
}

export function AcceleratorLongTerm({final, baseline}: IImpactProps) {
    const validatedSubmissions = getValidSubmissions(baseline, final);
    const filteredBaseline: IBaselineSubmissionProps[] = validatedSubmissions.filteredBaseline;
    const filteredFinal: IFinaleSubmissionProps[] = validatedSubmissions.filteredFinal;

    return <ChartsContainer<IBaselineSubmissionProps, IFinaleSubmissionProps>
        configSchema={configSchema}
        filteredBaseline={filteredBaseline}
        filteredFinal={filteredFinal}
    />
}