import {TotalResultHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

export const totalJobsCreatedTotalCalculation: TotalResultHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> = 
    (baselineSubmissions, finalSubmissions) => {
        const baselineTotalEmployees = baselineSubmissions.reduce(function (a, b) {
            return a + (Number(b.question13_total_column_1) || 0);
        }, 0);
        const finalTotalEmployees =  finalSubmissions.reduce(function (a, b) {
            return a + (Number(b.question13_total_column_1) || 0);
        }, 0);

        return finalTotalEmployees - baselineTotalEmployees;
    }