export const TYPE_COLUMN_TOTAL = 'column-total';
export const TYPE_COLUMN_HIGH_CHART = 'column-high-chart';

export interface ITitleSection {
    title: string
}

export type TotalResultHandler<Baseline, Finale> = (baseline: Baseline[], finale: Finale[]) => number|string;
export type HighChartHandler<Baseline, Finale> = (
    baseline: Baseline[],
    finale: Finale[],
    title?: string
) => Highcharts.Options;

export interface IChartColumn<Baseline, Finale> {
    title?: string;
    typeChart: string;
    transformHandler: HighChartHandler<Baseline, Finale> | TotalResultHandler<Baseline, Finale>;
}

export interface IChartRow<Baseline, Finale> {
    col?: number;
    columns: IChartColumn<Baseline, Finale>[];
} 

export interface IChartSection<Baseline, Finale> {
    titleSection?: ITitleSection;
    rows: IChartRow<Baseline, Finale>[];
}

export type IConfigSchema<Baseline, Finale> = IChartSection<Baseline, Finale>[];