import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const sum = <T>(
    collection: T[],
    key: keyof T
): number => {
    return collection.reduce((acc, submission) => {
        const value = submission[key];
        
        return typeof value === 'number' ? acc + value : acc;
    }, 0);
};

/**
 * == baseline
 * question28_row_1_column_1 - Full-time contracts
 * question28_row_2_column_1 - Part-time contracts
 * question28_total_column_1 - Total number of employees
 * 
 * == final
 * question85_row_1_column_1 - Full-time contracts
 * question85_row_2_column_1 - Part-time contracts
 * question85_total_column_1 - Total number of employees
 * 
 * @param baselineSubmissions 
 * @param finalSubmissions 
 * @param title 
 * @returns
 */
export const typeContractChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        return {
            chart: {
                type: 'column',
            },
            title: {
                text: title,
                align: 'left',
            },
            tooltip: {
                formatter: function (){
                    let value = this.series.name + '<br />';
                    value += `<b>${this.point.y?.toFixed(2)} (${this.point.percentage?.toFixed(1)}%)</b>`;

                    return value;
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: ['Baseline', 'Final'],
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: 'Full-time contracts',
                    type: 'column',
                    color: '#ed7d32',
                    data: [
                        sum(baselineSubmissions, 'question28_row_1_column_1') / sum(baselineSubmissions, 'question28_total_column_1') * 100,
                        sum(finalSubmissions, 'question85_row_1_column_1') / sum(finalSubmissions, 'question85_total_column_1') * 100,
                    ],
                },
                {
                    name: 'Part-time contracts',
                    type: 'column',
                    color: '#5b9bd5',
                    data: [
                        sum(baselineSubmissions, 'question28_row_2_column_1') / sum(baselineSubmissions, 'question28_total_column_1') * 100,
                        sum(finalSubmissions, 'question85_row_2_column_1') / sum(finalSubmissions, 'question85_total_column_1') * 100,
                    ],
                },
            ],
        };
    }