import _ from 'lodash';
import {TotalResultHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

/**
 * == baseline
 * question13_total_column_1 - Total number of employees
 * 
 * == final
 * question13_total_column_1 - Total number of employees
 * 
 * @param baselineSubmissions 
 * @param finalSubmissions 
 * @returns 
 */
export const averageJobsCreatedTotalCalculation: TotalResultHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions) => {
        const baselineMap = _.keyBy(baselineSubmissions, 'uoa_id');
        const finalMap = _.keyBy(finalSubmissions, 'uoa_id');

        const groupedByUoaId = _.mergeWith(baselineMap, finalMap, (baselineSubmission, finalSubmission) => ({
            baselineTotalEmployees: baselineSubmission ? baselineSubmission.question13_total_column_1 : null,
            finalTotalEmployees: finalSubmission ? finalSubmission.question13_total_column_1 : null,
        }));

        const positiveSubmissions = Object.values(groupedByUoaId).filter(
            ({finalTotalEmployees, baselineTotalEmployees}: any) => finalTotalEmployees > baselineTotalEmployees
        );
        const totalCount = positiveSubmissions.reduce(
            (acc, {finalTotalEmployees, baselineTotalEmployees}: any) => acc + (finalTotalEmployees - baselineTotalEmployees),
            0
        )

        return (totalCount / positiveSubmissions.length).toFixed(2);
    }