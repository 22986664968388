import {IBaselineSubmissionProps, IFinaleSubmissionProps} from './resources/Submission';
import {TYPE_COLUMN_TOTAL, TYPE_COLUMN_HIGH_CHART, IConfigSchema} from '../resources/config-schema';
import {averageJobsCreatedTotalCalculation} from './total-calculations/average-jobs-created-total-calculation';
import {totalJobsCreatedTotalCalculation} from './total-calculations/total-jobs-created-total-calculation';
import {newJobsGenderChartCalculation} from './chart-calculations/new-jobs-gender-chart-calculation';
import {newJobsTermContractChartCalculation} from './chart-calculations/new-jobs-term-contract-chart-calculation';
import {newJobsTypeContractChartCalculation} from './chart-calculations/new-jobs-type-contract-chart-calculation';

export const configSchema: IConfigSchema<IBaselineSubmissionProps, IFinaleSubmissionProps> = [
    {
        titleSection: {
            title: 'Job creation'
        },
        rows: [
            {
                columns: [
                    {
                        title: 'Average number of jobs created per entrepreneur',
                        typeChart: TYPE_COLUMN_TOTAL,
                        transformHandler: averageJobsCreatedTotalCalculation,
                    },
                    {
                        title: 'Total job created',
                        typeChart: TYPE_COLUMN_TOTAL,
                        transformHandler: totalJobsCreatedTotalCalculation,
                    }
                ]
            },
            {
                columns: [
                    {
                        title: 'New jobs by gender',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: newJobsGenderChartCalculation,
                    },
                ]
            },
            {
                columns: [
                    {
                        title: 'New jobs by term of contracts',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: newJobsTermContractChartCalculation,
                    },
                    {
                        title: 'New jobs by type of contracts',
                        typeChart: TYPE_COLUMN_HIGH_CHART,
                        transformHandler: newJobsTypeContractChartCalculation,
                    },
                ],
            },
        ]
    },
]