import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'I stopped the business for personal reasons although it was going well',
        value: 'Item 1',
        color: '#5b9bd5',
    },
    {
        name: 'I stopped the business because it wasn’t going well',
        value: 'Item 2',
        color: '#ed7d32',
    },
    {
        name: 'I sold the business',
        value: 'Item 3',
        color: '#a5a5a5',
    },
];

/**
 * == final
 * question7
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const failureFactorsChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: finalSubmission.filter((submission) => submission.question16 === el.value).length,
        }));

        return getDefaultPieFormatting(
            title || null,
            data,
            0,
            {},
            finalSubmission.filter((submission) => !submission.question16).length
        ) as Highcharts.Options;
    }