import _ from 'lodash';
import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Permanent contracts',
        value: 'totalCountPermanentContracts',
        color: '#5b9bd5',
    },
    {
        name: 'Fix-term contracts',
        value: 'totalCountFixTermContracts',
        color: '#ed7d32',
    },
];

/**
 * == baseline
 * question27_row_1_column_1 - Permanent contracts
 * question27_row_2_column_1 - Fix-term contracts
 *
 * == final
 * question84_row_1_column_1 - Permanent contracts
 * question84_row_2_column_1 - Fix-term contracts
 *
 * @param baselineSubmissions
 * @param finalSubmissions
 * @param title
 * @returns
 */
export const newJobsTermContractChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        const calculateDifferences = (baselineField: string, finalField: string) => {
            const baselineValue = baselineSubmissions.reduce(function (a, b: any) {
                return a + (Number(b[baselineField]) || 0);
            }, 0);
            const finalValue =  finalSubmissions.reduce(function (a, b: any) {
                return a + (Number(b[finalField]) || 0);
            }, 0);

            return finalValue - baselineValue;
        };

        const totalCountPermanentContracts = calculateDifferences('question27_row_1_column_1', 'question84_row_1_column_1');
        const totalCountFixTermContracts = calculateDifferences('question27_row_2_column_1', 'question84_row_2_column_1');
        
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: el.value === 'totalCountPermanentContracts' ? totalCountPermanentContracts : totalCountFixTermContracts,
        }));
        
        return getDefaultPieFormatting(title || null, data) as Highcharts.Options;
    }