import * as React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';

interface ITotalResultContainerProps {
    title: string;
    total: number|string;
}

export function TotalResultContainer({title, total}: ITotalResultContainerProps) {
    return (
        <div className='space-y-4'>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <Box
                            sx={{
                                width: '4rem',
                                p: 1,
                                border: '1px solid grey',
                                textAlign: 'right',
                                fontSize: '1.25rem',
                                backgroundColor: 'rgb(243, 244, 246)',
                            }}
                        >
                            {total}
                        </Box>
                        <Typography>{title}</Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}