import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: '0 - 5 %',
        value: 'Item 1',
        color: '#d9ead3',
    },
    {
        name: '6 - 25%',
        value: 'Item 2',
        color: '#b6d7a8',
    },
    {
        name: '26 - 50%',
        value: 'Item 3',
        color: '#93c47d',
    },
    {
        name: '51 - 75%',
        value: 'Item 4',
        color: '#6aa84e',
    },
    {
        name: '76 - 100%',
        value: 'Item 5',
        color: '#38761d',
    },
];

/**
 * == final
 * question4
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const contributionProgramChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: finalSubmission.filter((submission) => submission.question4 === el.value).length /
                finalSubmission.length * 100,
        }));

        return {
            chart: {
                type: 'bar',
            },
            title: {
                text: title,
                align: 'left',
            },
            tooltip: {
                pointFormat: `<b>{point.y:.${2}f} ({point.percentage:.1f}%)</b>`
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
                labels: {
                    format: '{value}%',
                    step: 5,
                },
            }, 
            xAxis: {
                categories: [
                    '0 - 5 %',
                    '6 - 25%',
                    '26 - 50%',
                    '51 - 75%',
                    '76 - 100%',
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Percent',
                    data,
                    type: 'column',
                    dataLabels: {
                        enabled: false,
                    },
                },
            ],
        }
    }