import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {IChartColumn} from '../resources/config-schema';

interface IChartContainerProps<Baseline, Final> {
    column: IChartColumn<Baseline, Final>;
    baselineSubmissions: Baseline[];
    finalSubmissions: Final[];
}

export function HighChartContainer<Baseline, Final>(
    {column, baselineSubmissions, finalSubmissions}: IChartContainerProps<Baseline, Final>
) {
    return (
        <Card className='chart-container'>
            <CardContent>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={
                        column.transformHandler(
                            baselineSubmissions,
                            finalSubmissions,
                            column.title,
                        )
                    }
                />
            </CardContent>
        </Card>
    )
}