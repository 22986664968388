import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'I was able to grow my business',
        value: 'item1',
        color: '#5b9bd5',
    },
    {
        name: 'I started another business in addition to the one I had',
        value: 'item2',
        color: '#ed7d32',
    },
    {
        name: 'Nothing has changed',
        value: 'item3',
        color: '#a5a5a5',
    },
    {
        name: 'I closed my business',
        value: 'item4',
        color: '#fec000',
    },
];

/**
 * == final
 * question5
 *
 * @param baselineSubmission
 * @param finalSubmission
 * @param title
 * @returns
 */
export const businessScaledChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: finalSubmission.filter((submission) => submission.question5 === el.value).length,
        }));

        return getDefaultPieFormatting(
            title || null, data,
            0,
            {},
            finalSubmission.filter((submission) => !submission.question5).length
        ) as Highcharts.Options;
    }