import _ from 'lodash';
import {TotalResultHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

/**
 * == baseline
 * question13_total_column_1 - Total number of employees
 * 
 * == final
 * question13_total_column_1 - Total number of employees
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @returns 
 */
export const increasedEmployeesTotalCalculation: TotalResultHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission) => {
        const baselineMap = _.keyBy(baselineSubmission, 'uoa_id');
        const finalMap = _.keyBy(finalSubmission, 'uoa_id');

        let result = _.mergeWith(baselineMap, finalMap, (base, fin, key) => ({
            baselineValue: base ? base.question13_total_column_1 : null,
            finalValue: fin ? fin.question13_total_column_1 : null
        }));

        const totalSubmissions = Object.values(result).length;

        result = _.pickBy(result, ({ baselineValue, finalValue}: any) =>
            finalValue != null &&
            baselineValue != null &&
            finalValue > baselineValue
        )

        return (Object.values(result).length * 100 / totalSubmissions).toFixed(2)
    }