import {useEffect, useState} from 'react';
import {IFoodBanksProps} from '../../utils/models';
import PieChart from '../../PieChart';

const FoodOutDestination = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let collected = 0, other = 0;

        submissions.forEach((submission) => {
            collected = collected + (Number(submission.food_delivered_to_charitable_organisations) || 0)
            other = other + (
                (Number(submission.food_delivered_to_other_food_banks) || 0)
                + (Number(submission.warehouse_wasted_food) || 0)
            )
        })

        setSeries([{
            name: 'Sources',
            size: '100%',
            innerSize: '50%',
            data: [
                {
                    name: 'Charitable organisations',
                    y: collected,
                    color: '#ed8535'
                },
                {
                    name: 'Other',
                    y: other,
                    color: '#b8bbc3'
                },
            ],
        }]);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series && (
                    <PieChart
                        title='Food Out destination'
                        fractionDigits={2}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default FoodOutDestination;