import _ from 'lodash';
import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Women',
        value: 'totalCountFemale',
        color: '#5b9bd5',
    },
    {
        name: 'Men',
        value: 'totalCountMale',
        color: '#ed7d32',
    },
];

/**
 * == baseline
 * question13_row_1_column_1 - Female
 * question13_row_2_column_1 - Male
 *
 * == final
 * question13_row_1_column_1 - Female
 * question13_row_2_column_1 - Male
 *
 * @param baselineSubmissions
 * @param finalSubmissions
 * @param title
 * @returns
 */
export const newJobsGenderChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        const calculateDifferences = (field: string) => {
            const baselineTotalEmployees = baselineSubmissions.reduce(function (a, b: any) {
                return a + (Number(b[field]) || 0);
            }, 0);
            const finalTotalEmployees =  finalSubmissions.reduce(function (a, b: any) {
                return a + (Number(b[field]) || 0);
            }, 0);

            return finalTotalEmployees - baselineTotalEmployees;
        };

        const totalCountFemale = calculateDifferences('question13_row_1_column_1');
        const totalCountMale = calculateDifferences('question13_row_2_column_1');

        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: el.value === 'totalCountFemale' ? totalCountFemale : totalCountMale,
        }));
        
        return getDefaultPieFormatting(title || null, data) as Highcharts.Options;
    }