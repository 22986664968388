import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

/**
 * == final
 * question21
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const levelEventsChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        const data: {name: string, y: number}[] = [];

        for (let i = 1; i <= 7; i++) {
            data.push({
                name: String(i),
                y: finalSubmissions.filter(submission => Number(submission.question21) === i).length / finalSubmissions.length * 100
            })
        }
        
        return {
            chart: {
                type: 'column',
            },
            title: {
                text: title,
                align: 'left',
            },
            tooltip: {
                pointFormat: `<b>{point.y:.${2}f} ({point.percentage:.1f}%)</b>`
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Percent',
                    data: data,
                    type: 'column',
                    dataLabels: {
                        enabled: false,
                    },
                },
            ],
        };
    }