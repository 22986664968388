import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IRequestedFundsDuringProps {
    submissions: any[];
}

const RequestedFundsDuring = ({submissions}: IRequestedFundsDuringProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const success = submissions.filter((submission: any) => submission.question8 === 'item1').length;
        const unSuccess = submissions.filter((submission: any) => submission.question8 === 'item2').length;

        const title = '% of entrepreneurs who requested funds during the acceleration program';
        const data = [
            {
                name: 'Yes',
                y: success,
                color: COLORS.blue,
            },
            {
                name: 'No',
                y: unSuccess,
                color: COLORS.yellow,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(
            title,
            data,
            1,
            {},
            submissions.filter((submission) => !submission.question8).length
        );
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default RequestedFundsDuring;
