import _ from 'lodash';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';
import {percentageFormatter} from '../../helpers/formatters';

const transform = [
    {
        name: 'Baseline',
        filterKey: 'baselineValue',
    },
    {
        name: 'Final',
        filterKey: 'finalValue',
    },
];

const sum = (
    collection: _.Dictionary<IBaselineSubmissionProps> & _.Dictionary<IFinaleSubmissionProps>,
    key: string
): number => _.sumBy(_.values(collection), key)

/**
 * == baseline
 * question13_row_1_column_1 - Female
 * question13_total_column_1 - Total number of employees
 * 
 * == final
 * question13_row_1_column_1 - Female
 * question13_total_column_1 - Total number of employees
 * 
 * @param baselineSubmissions 
 * @param finalSubmissions 
 * @param title 
 * @returns 
 */
export const womenEmployedChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        const baselineMap = _.keyBy(baselineSubmissions, 'uoa_id');
        const finalMap = _.keyBy(finalSubmissions, 'uoa_id');

        const groupedByUoaId = _.mergeWith(baselineMap, finalMap, (baselineSubmission, finalSubmission) => ({
            baselineCountFemale: baselineSubmission ? baselineSubmission.question13_row_1_column_1 : null,
            baselineTotalEmployees: baselineSubmission ? baselineSubmission.question13_total_column_1 : null,
            finalCountFemale: finalSubmission ? finalSubmission.question13_row_1_column_1 : null,
            finalTotalEmployees: finalSubmission ? finalSubmission.question13_total_column_1 : null,
        }));

        const result: any = {
            baselineValue: sum(groupedByUoaId, 'baselineCountFemale') / sum(groupedByUoaId, 'baselineTotalEmployees'),
            finalValue: sum(groupedByUoaId, 'finalCountFemale') / sum(groupedByUoaId, 'finalTotalEmployees')
        }

        const data = transform.map((el) => ({
            name: el.name,
            y: result[el.filterKey] * 100
        }));

        return {
            chart: {
                type: 'column',
            },
            title: {
                text: title,
                align: 'left',
            },
            tooltip: {
                pointFormat: `<b>{point.y:.${2}f} ({point.percentage:.1f}%)</b>`
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Percent',
                    data: data,
                    type: 'column',
                    dataLabels: {
                        enabled: false,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };
    }