import * as React from 'react';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import Loader from '../../shared/Loader';
import apiCall, {setUserToken} from '../../utils/api';
import CustomSelect from '../../shared/CustomSelect';
import FoodBank from './FoodBank';
import FoodInFoodOut from './FoodInFoodOut';
import NonFoodInNonFoodOut from './NonFoodInNonFoodOut';
import HumanResources from './HumanResources';
import Infrastructure from './Infrastructure';
import Energy from './Energy';
import Partners from './Partners';

import './styles.scss';

const DEFAULT_YEAR_VALUE = 'All';
const DEFAULT_YEAR_LABEL = 'All (Year 2021-2023)';
const ORGANIZATION_ROMANIA = '92';

const Feba = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_FEBA || '');
    const containerId = searchParams.get('container_id');
    const typeId = searchParams.get('type_id');
    const uoaId = searchParams.get('uoa_id');
    const foodBank = searchParams.get('food_bank');
    const foodIn = searchParams.get('food_in');
    const nonFoodIn = searchParams.get('non_food_in');
    const human = searchParams.get('human');
    const infrastructure = searchParams.get('infrastructure');
    const energy = searchParams.get('energy');
    const partners = searchParams.get('partners');
    const hashKey = searchParams.get('key');
    const defaultCheckpoint = searchParams.get('default_checkpoint');

    setUserToken(searchParams.get('token') || '')

    const [loading, setLoading] = useState<boolean>(true);
    const [uoas, setUoas] = useState([]);
    const [years, setYears] = useState<any[]>([]);
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [selectedYear, setSelectedYear] = useState<string>('All');
    const [showAllData, setShowAllData] = useState<boolean>(false);
    const [showUoaFilter, setShowUoaFilter] = useState<boolean>(false);

    const showCheckpointFilter = typeId !== ORGANIZATION_ROMANIA;

    const getFilterData = async () => {
        if (uoaId) {
            setSelectedUoa(uoaId)
        }

        if (containerId && typeId && hashKey === hashKeyLocal) {
            const requests = [
                await apiCall({
                    url: `containers/${container}/uoa`,
                    params: {
                        type_id: typeId,
                        pagination: false,
                    }
                }),
                await apiCall({
                    url: `containers/${container}/checkpoints`,
                    params: {
                        container_id: containerId,
                    }
                }),
            ]

            const responses = await Promise.all(requests);

            if (responses.length > 0 && responses[0].data.hasOwnProperty('items')) {
                setUoas(responses[0].data.items.map((item: { id: number, title: string }) => ({
                    name: item.title,
                    id: item.id,
                })));
            }

            if (responses.length > 1 && responses[1].data.hasOwnProperty('checkpoints')) {
                const values = responses[1].data.checkpoints.map((item: { id: number, name: string }) => ({
                    name: item.name,
                    id: item.id,
                }));

                values.unshift({
                    name: DEFAULT_YEAR_LABEL,
                    id: DEFAULT_YEAR_VALUE,
                });

                setYears(values);
            }
        }
    }

    useEffect(() => {
        getFilterData().then(_ => setLoading(!(hashKey === hashKeyLocal)));
    }, []);


    useEffect(() => {
        const defaultYear = years.find((item) => Number(item.id) === Number(defaultCheckpoint));

        if (defaultYear) {
            setSelectedYear(defaultYear.id);
        }
    }, [years]);

    useEffect(() => {
        setShowAllData(selectedYear !== 'All')
    }, [selectedYear]);

    useEffect(() => {
        setShowUoaFilter(!uoaId && uoas.length > 1)
    }, [uoas]);

    return (
        <div className='container space-y-8'>
            {
                !loading && (
                    <>
                        <div className='filter'>
                            {
                                showCheckpointFilter && <CustomSelect
                                    items={years}
                                    value={selectedYear}
                                    onChange={(value: any) => setSelectedYear(value)}
                                    label='Year'
                                    className='filter-select'
                                    defaultVariant={DEFAULT_YEAR_VALUE}
                                    defaultLabel={DEFAULT_YEAR_LABEL}
                                />
                            }
                            {
                                showUoaFilter && (
                                    <CustomSelect
                                        items={uoas}
                                        value={selectedUoa}
                                        onChange={(value: any) => setSelectedUoa(value)}
                                        label=''
                                        className='filter-select'
                                        defaultVariant='All'
                                    />
                                )
                            }
                        </div>
                        <div>
                            {
                                showAllData && foodBank && (
                                    <FoodBank
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={foodBank}
                                    />
                                )
                            }
                            {
                                foodIn && (
                                    <FoodInFoodOut
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={foodIn}
                                    />
                                )
                            }
                            {
                                nonFoodIn && (
                                    <NonFoodInNonFoodOut
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={nonFoodIn}
                                    />
                                )
                            }
                            {
                                showAllData && human && (
                                    <HumanResources
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={human}
                                    />
                                )
                            }
                            {
                                showAllData && infrastructure && (
                                    <Infrastructure
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={infrastructure}
                                    />
                                )
                            }
                            {
                                showAllData && energy && (
                                    <Energy
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={energy}
                                    />
                                )
                            }
                            {
                                showAllData && partners && (
                                    <Partners
                                        container={container}
                                        selectedYear={selectedYear}
                                        selectedUoa={selectedUoa}
                                        survey={partners}
                                    />
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className='loader-container'>
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
}

export default Feba;