import {TotalResultHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

/**
 * == final
 * question5
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @returns 
 */
export const businessScaledTotalCalculation: TotalResultHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission) => {
        const filteredCount = finalSubmission.filter(
            (submission) => ['item1', 'item2'].includes(submission.question5)
        ).length

        return (filteredCount / finalSubmission.length * 100).toFixed(2)
    }