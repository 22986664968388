import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Mentor support',
        value: 'Item 1',
        color: '#5b9bd5',
    },
    {
        name: 'Increased self-confidence in the business idea',
        value: 'Item 2',
        color: '#ed7d32',
    },
    {
        name: 'Having found a financier',
        value: 'Item 3',
        color: '#a5a5a5',
    },
    {
        name: 'Other',
        value: 'other',
        color: '#fec000',
    },
];

/**
 * == final
 * question7
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const successFactorsChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: finalSubmission.filter((submission) => submission.question7 === el.value).length,
        }));

        return getDefaultPieFormatting(
            title || null,
            data,
            0,
            {},
            finalSubmission.filter((submission) => !submission.question7).length
        ) as Highcharts.Options;
    }