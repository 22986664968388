import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Yes',
        value: 'Yes',
        color: '#5b9bd5',
    },
    {
        name: 'No',
        value: 'No',
        color: '#ed7d32',
    },
];

/**
 * == final
 * question11
 *
 * @param baselineSubmissions
 * @param finalSubmissions
 * @param title
 * @returns
 */
export const participationEventsChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmissions, finalSubmissions, title) => {
        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: finalSubmissions.filter(submission => submission.question11 === el.value).length
        }));
        
        return getDefaultPieFormatting(title || null, data) as Highcharts.Options;
    }