import _ from 'lodash';
import {getDefaultPieFormatting} from '../../charts/pie';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Increased',
        checker: (baselineValue: number, finalValue: number): boolean => finalValue > baselineValue,
        color: '#5b9bd5',
    },
    {
        name: 'Non increased',
        checker: (baselineValue: number, finalValue: number): boolean => finalValue < baselineValue,
        color: '#ed7d32',
    },
];

/**
 * == baseline
 * question5_turnover_value
 * 
 * == final
 * question79_turnover_value
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const increasedTurnoverChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const baselineMap = _.keyBy(baselineSubmission, 'uoa_id');
        const finalMap = _.keyBy(finalSubmission, 'uoa_id');

        const result = _.mergeWith(baselineMap, finalMap, (base, fin, key) => ({
            baselineValue: base ? base.question5_turnover_value : null,
            finalValue: fin ? fin.question79_turnover_value : null
        }));

        const data = transform.map((el) => ({
            name: el.name,
            color: el.color,
            y: _.reduce(result, (acc, {baselineValue, finalValue}: any) => {
                if (!finalValue || !baselineValue) return acc;

                if (el.checker(baselineValue, finalValue)) acc++;

                return acc;
            }, 0),
        }));
        
        return getDefaultPieFormatting(title || null, data) as Highcharts.Options;
    }