import _ from 'lodash';
import {percentageFormatter} from '../../helpers/formatters';
import {HighChartHandler} from '../../resources/config-schema';
import {IBaselineSubmissionProps, IFinaleSubmissionProps} from '../resources/Submission';

const transform = [
    {
        name: 'Avg turnover baseline',
        filterKey: 'baselineValue',
    },
    {
        name: 'Avg turnover final',
        filterKey: 'finalValue',
    },
];

/**
 * == baseline
 * question5_turnover_value
 * 
 * == final
 * question79_turnover_value
 * 
 * @param baselineSubmission 
 * @param finalSubmission 
 * @param title 
 * @returns 
 */
export const levelIncreasedChartCalculation: HighChartHandler<IBaselineSubmissionProps, IFinaleSubmissionProps> =
    (baselineSubmission, finalSubmission, title) => {
        const baselineMap = _.keyBy(baselineSubmission, 'uoa_id');
        const finalMap = _.keyBy(finalSubmission, 'uoa_id');

        let result = _.mergeWith(baselineMap, finalMap, (base, fin, key) => ({
            baselineValue: base ? base.question5_turnover_value : null,
            finalValue: fin ? fin.question79_turnover_value : null
        }));

        result = _.pickBy(result, ({ baselineValue, finalValue}: any) =>
            finalValue != null &&
            baselineValue != null &&
            finalValue > baselineValue
        )
    
        const data = transform.map((el) => ({
            name: el.name,
            y: _.mean(
                _.map(result, el.filterKey)
            ),
        }));

        return {
            chart: {
                type: 'column',
            },
            title: {
                text: title,
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data,
                    type: 'column',
                    dataLabels: {
                        enabled: false,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };
    }