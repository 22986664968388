import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {HighChartContainer} from '../Common/HighChartContainer';
import {TotalResultContainer} from '../Common/TotalResultContainer';
import {IConfigSchema, TotalResultHandler, TYPE_COLUMN_TOTAL} from '../resources/config-schema';

interface IChartsContainerProps<Baseline, Final> {
    configSchema: IConfigSchema<Baseline, Final>;
    filteredBaseline: Baseline[];
    filteredFinal: Final[];
}

export function ChartsContainer<Baseline, Final>(
    {configSchema, filteredBaseline, filteredFinal}: IChartsContainerProps<Baseline, Final>
) {
    return (
        <>
            {configSchema.map((chartSection, index) => (
                <div key={index}>  
                    {chartSection?.titleSection && 
                        <Box marginY={2}>
                            <Typography
                                variant='h6'
                            >
                                {chartSection.titleSection.title}
                            </Typography>
                        </Box>
                    }
                    {chartSection.rows.map((row, index) =>
                        <Grid key={index} container columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginY={2}>
                            {row.columns.map((column, index) =>
                                <Grid key={index} item xs={12 / row.columns.length}>
                                    {column.typeChart === TYPE_COLUMN_TOTAL ?
                                        <TotalResultContainer
                                            title={column.title || ''}
                                            total={(column.transformHandler as TotalResultHandler<Baseline, Final>)(filteredBaseline, filteredFinal)}
                                        />
                                    :
                                        <HighChartContainer<Baseline, Final>
                                            column={column}
                                            baselineSubmissions={filteredBaseline}
                                            finalSubmissions={filteredFinal}
                                        />
                                    }
                                </Grid>
                            )}
                        </Grid>
                    )}
                </div>
            ))}
        </>
    )
}