import * as React from 'react';
import _ from 'lodash';
import {SyntheticEvent, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import {Box, Tabs} from '@mui/material';
import StyledTab from '../../shared/Tabs/StyledTab';
import TabPanel from '../../shared/Tabs/TabPanel';
import Loader from '../../shared/Loader';
import CustomSelect from '../../shared/CustomSelect';
import apiCall, {setUserToken} from '../../utils/api';
import {greater} from '../../utils/dates';
import Output from './Output';
import ShortTerm from './ShortTerm';
import MidTerm from './MidTerm';
import AcceleratorMidTerm from './AcceleratorMidTerm';
import LongTerm from './LongTerm';
import Impact from './Impact';
import {AcceleratorLongTerm} from './AcceleratorLongTerm';
import {AcceleratorImpact} from './AcceleratorImpact';
import {IFieldProps} from '../../resources/Field';
import {ContainerProvider} from '../../contexts/Container';
import {getValueByType, TYPE_ACCELERATOR, TYPE_MBA_STUDENT} from './helpers/mapping';

import './styles.scss';

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const E4ImpactMBA = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_E4_IMPACT || '');
    const containerId = searchParams.get('container_id');
    const typeId = searchParams.get('type_id');
    const baselineId = searchParams.get('baseline_id');
    const finalId = searchParams.get('final_id');
    const hashKey = searchParams.get('key');
    const uoaId = searchParams.get('uoa_id');

    setUserToken(searchParams.get('token') || '');

    const businessSectorQuestionId = getValueByType(typeId || '', 'type_of_sector');

    const [loading, setLoading] = useState(true);
    const [baseline, setBaseline] = useState<any[]>([]);
    const [filteredBaseline, setFilteredBaseline] = useState<any[]>([]);
    const [final, setFinal] = useState<any[]>([]);
    const [fields, setFields] = useState<{ id: number; key: string; options: any[] }[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [genderField, setGenderField] = useState<IFieldProps | undefined>(undefined);
    const [highPotentialField, setHighPotentialField] = useState<IFieldProps | undefined>(undefined);
    const [editions, setEditions] = useState<any[]>([]);
    const [editionsCount, setEditionsCount] = useState(0);
    const [selectableEditions, setSelectableEditions] = useState<any[]>([]);
    const [uoas, setUoas] = useState<any[]>([]);
    const [tab, setTab] = useState(0);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>('All');
    const [selectedGender, setSelectedGender] = useState<string>('All');
    const [selectedHighPotential, setSelectedHighPotential] = useState<string>('All');
    const [selectedEdition, setSelectedEdition] = useState<string>('All');
    const [businessSectorChoices, setBusinessSectorChoices] = useState([]);
    const [impactSampleSectorChoices] = useState([{id: 1, name: 'Yes'}, {id: 0, name: 'No'}]);
    const [selectedBusinessSector, setSelectedBusinessSector] = useState<string>('All');
    const [selectedImpactSampleSector, setSelectedImpactSampleSector] = useState<string>('All');
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [entrepeneurs, setEntrepeneurs] = useState<number>(0);
    const [women, setWomen] = useState<number>(0);
    const [youngEntrepeneurs, setYoungEntrepeneurs] = useState<number>(0);
    const [levelIds, setLevelIds] = useState<any[] | null>(null);
    const [activeEntrepeneurs, setActiveEntrepeneurs] = useState<{ active: number; not_active: number }>({
        active: 0,
        not_active: 0,
    });

    const filterSubmissionsByBusinessSector = function (submissions: any[]) {
        setFilteredBaseline(
            submissions.filter(
                (item: any) =>
                    selectedBusinessSector === 'All' ||
                    item[businessSectorQuestionId] === selectedBusinessSector
            )
        );
    };

    const getSubmissionsFilter = (surveyId: string | null) => ({
        survey_id: surveyId,
        ...getDataFilter(),
        additional_data: false,
    });

    const getDataFilter = () => ({
        ...((uoaId || selectedUoa !== 'All') && {uoa_id: uoaId || selectedUoa}),
        ...(levelIds && {level_ids: levelIds}),
        ...(genderField && selectedGender !== 'All' && {custom_fields: {[genderField.id]: selectedGender}}),
        ...(highPotentialField &&
            selectedHighPotential !== 'All' && {custom_fields: {[highPotentialField.id]: selectedHighPotential}}),
    });

    const getSurveysData = async () => {
        setLoading(true);

        await getUoaData();

        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: getSubmissionsFilter(baselineId),
            }),
            await apiCall({
                url: `containers/${container}/submissions`,
                params: getSubmissionsFilter(finalId),
            }),
        ];

        const responses = await Promise.all(requests);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
            const submissions = _.uniqBy(responses[0].data.submissions, 'uoa_id');
            setBaseline(submissions);
            filterSubmissionsByBusinessSector(submissions);
        }

        if (responses.length > 1 && responses[1].data.hasOwnProperty('submissions')) {
            setFinal(_.uniqBy(responses[1].data.submissions, 'uoa_id'));
        }
    };

    const getUoaData = async () => {
        if (containerId && typeId) {
            const genderField = fields.find((field) => field.key === 'genere');
            const birthdayDateField = fields.find(
                (field) =>
                    field.key ===
                    'data-di-nascita-attenzione-inserire-la-data-di-nascita-corretta-e-non-una-data-recente'
            );
            const statusField = fields.find((field) => field.key === 'status');

            const requests = [
                await apiCall({
                    url: `containers/${container}/uoa/search`,
                    params: {
                        container_id: containerId,
                        pagination: 'false',
                        type_id: typeId,
                        ...(selectedImpactSampleSector !== 'All' && {
                            survey_ids: {
                                ids: [baselineId, finalId],
                                exclude: Number(!selectedImpactSampleSector)
                            }
                        }),
                        ...getDataFilter(),
                    },
                }),
            ];

            const responses = await Promise.all(requests);

            if (responses.length > 0 && responses[0].data.hasOwnProperty('data')) {
                const items = responses[0].data.data;

                setUoas(items.map((item: any) => ({name: item.title, id: item.id})));
                setEntrepeneurs(items.length);

                if (genderField) {
                    const femaleOption = genderField.options.find((option: any) => option.key === 'female');
                    const isWomenCondition = (customField: any) =>
                        customField.field_id === genderField.id &&
                        customField.stored_value === femaleOption.id.toString();
                    const womenItems = items.filter((item: any) => item.custom_field_values.some(isWomenCondition));
                    setWomen(womenItems.length);
                }

                if (birthdayDateField) {
                    setYoungEntrepeneurs(
                        items.filter((item: any) =>
                            item.custom_field_values.some(
                                (customField: any) =>
                                    customField.field_id === birthdayDateField.id &&
                                    greater(customField.value, '1994-12-31')
                            )
                        ).length
                    );
                }

                if (statusField) {
                    const activeOption = statusField.options.find((option: any) => option.key === 'attivo');
                    const notActiveOption = statusField.options.find((option: any) => option.key === 'non-attivo');
                    let active = 0;
                    let notActive = 0;

                    items.forEach((item: any) => {
                        const value = item.custom_field_values.find(
                            (customField: any) => customField.field_id === statusField.id
                        );

                        if (value) {
                            if (value.stored_value === activeOption.id.toString()) {
                                active++;
                            } else if (value.stored_value === notActiveOption.id.toString()) {
                                notActive++;
                            }
                        }
                    });

                    setActiveEntrepeneurs({
                        active: active,
                        not_active: notActive,
                    });
                }
            }
        }
    };

    const getFilterData = async () => {
        if (containerId && hashKey === hashKeyLocal && baselineId && finalId) {
            const requests = [
                await apiCall({
                    url: `containers/${container}/fields`,
                    params: {
                        container_id: containerId,
                        pagination: 'false',
                    },
                }),
                await apiCall({
                    url: `containers/${container}/breakdowns`,
                    params: {
                        container_id: containerId,
                    },
                }),
                await apiCall({
                    url: `containers/${container}/surveys/questions/choices`,
                    params: {
                        surveyId: baselineId,
                        questionId: businessSectorQuestionId,
                    },
                }),
            ];

            const responses = await Promise.all(requests);

            if (responses.length > 0 && responses[0].data.hasOwnProperty('data')) {
                setFields(responses[0].data.data);
                const gender = responses[0].data.data.find((field: IFieldProps) => field.key === 'genere');

                if (gender) {
                    setGenderField(gender);
                }

                const highPotential = responses[0].data.data.find(
                    (field: IFieldProps) => field.key === 'alto-potenziale'
                );

                if (highPotential) {
                    setHighPotentialField(highPotential);
                }
            }

            if (responses.length > 1 && responses[1].data.hasOwnProperty('items')) {
                let slug = 'activities';

                if (Number(typeId) === TYPE_ACCELERATOR) {
                    slug = 'business-accelerator-and-incubator';
                }

                const mbas = responses[1].data.items.find((breakdown: any) => breakdown.slug === slug);

                if (mbas) {
                    const countries: any = mbas.levels.filter((level: any) => level.parent_id === null);
                    const countriesIds = countries.map((country: any) => country.id);
                    setCountries(countries);
                    setEditions(mbas.levels.filter((level: any) => countriesIds.includes(level.parent_id)));
                }
            }

            if (responses.length > 2 && responses[2].data.hasOwnProperty('choices')) {
                setBusinessSectorChoices(
                    responses[2].data.choices.map((choice: any) => ({
                        id: choice.value,
                        name: choice.text,
                    }))
                );
            }
        }
    };

    const setLevelIdFromSelected = () => {
        if (selectedEdition !== 'All') {
            if (selectedCountry !== 'All') {
                setLevelIds([selectedEdition]);
                setEditionsCount(1);
            } else {
                const levels = editions
                    .filter((edition: any) => edition.name.endsWith(selectedEdition))
                    .map((edition: any) => edition.id);
                setLevelIds(levels);
                setEditionsCount(levels.length);
            }

            return;
        }

        if (selectedCountry !== 'All') {
            setLevelIds([selectedCountry]);
            setEditionsCount(selectableEditions.length);

            return;
        }

        setEditionsCount(editions.length);
        setLevelIds(null);
    };

    useEffect(() => {
        setSelectedEdition('All');
    }, [selectedCountry]);

    useEffect(() => {
        getFilterData().then((_) => setIsReady(true));
    }, []);

    useEffect(() => {
        if (isReady) {
            getSurveysData().then((_) => setLoading(hashKey !== hashKeyLocal));
        }
    }, [isReady, levelIds, selectedUoa, selectedGender, selectedHighPotential, selectedBusinessSector, selectedImpactSampleSector]);

    useEffect(() => {
        if (isReady) {
            if (selectedCountry !== 'All') {
                const selectableEditions = editions.filter((edition: any) => edition.parent_id === selectedCountry);
                setSelectableEditions(selectableEditions);
            } else {
                let years = editions.map((edition: any) => edition.name.split(' ').at(-1));

                if (Number(typeId) === TYPE_ACCELERATOR) {
                    years = editions.map((edition: any) => edition.name.substring(3));
                }

                //Get unique values
                years = years.filter((year, index, array) => array.indexOf(year) === index);
                setSelectableEditions(years.map((year: any) => ({id: year, name: year})));
            }
        }
    }, [isReady, selectedCountry]);

    useEffect(() => {
        setLevelIdFromSelected();
    }, [selectedCountry, selectedEdition, isReady]);

    useEffect(() => {
        if (loading) return;

        //Force refresh with a timeout
        setLoading(true);
        filterSubmissionsByBusinessSector(baseline);
        setTimeout(() => setLoading(false), 50);
    }, [selectedBusinessSector]);

    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <div className='container space-y-8'>
            {!loading && (
                <ContainerProvider type={typeId}>
                    {!uoaId && (
                        <div className='filter space-x-4'>
                            <CustomSelect
                                items={uoas}
                                value={selectedUoa}
                                onChange={(value: any) => setSelectedUoa(value)}
                                label='UoA'
                                className='uoa-select'
                                defaultVariant='All'
                            />
                            <CustomSelect
                                items={countries}
                                value={selectedCountry}
                                onChange={(value: any) => setSelectedCountry(value)}
                                label='Country'
                                className='uoa-select'
                                defaultVariant='All'
                            />
                            <CustomSelect
                                items={selectableEditions}
                                value={selectedEdition}
                                onChange={(value: any) => setSelectedEdition(value)}
                                label='Edition'
                                className='uoa-select'
                                defaultVariant='All'
                            />
                            {genderField && (
                                <CustomSelect
                                    items={genderField.options}
                                    value={selectedGender}
                                    onChange={(value: any) => setSelectedGender(value)}
                                    label={genderField.name}
                                    className='uoa-select'
                                    defaultVariant='All'
                                />
                            )}
                            {highPotentialField && (
                                <CustomSelect
                                    items={highPotentialField.options}
                                    value={selectedHighPotential}
                                    onChange={(value: any) => setSelectedHighPotential(value)}
                                    label={highPotentialField.name}
                                    className='uoa-select'
                                    defaultVariant='All'
                                />
                            )}
                            <CustomSelect
                                items={businessSectorChoices}
                                value={selectedBusinessSector}
                                onChange={(value: any) => setSelectedBusinessSector(value)}
                                label='Business Sector'
                                className='uoa-select'
                                defaultVariant='All'
                            />
                            {
                                Number(typeId) === TYPE_MBA_STUDENT && <CustomSelect
                                    items={impactSampleSectorChoices}
                                    value={selectedImpactSampleSector}
                                    onChange={(value: any) => setSelectedImpactSampleSector(value)}
                                    label='Impact sample'
                                    className='uoa-select'
                                    defaultVariant='All'
                                />
                            }
                        </div>
                    )}

                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={tab}
                            onChange={handleChange}
                            aria-label='tabs'
                        >
                            <StyledTab
                                label='OUTPUT'
                                {...tabProps(0)}
                            />
                            <StyledTab
                                label='SHORT-TERM OUTCOMES'
                                {...tabProps(1)}
                            />
                            <StyledTab
                                label='MID-TERM OUTCOMES'
                                {...tabProps(1)}
                            />
                            <StyledTab
                                label='LONG-TERM OUTCOMES'
                                {...tabProps(1)}
                            />
                            <StyledTab
                                label='IMPACT'
                                {...tabProps(1)}
                            />
                        </Tabs>
                    </Box>

                    <TabPanel
                        value={tab}
                        index={0}
                    >
                        <Output
                            baseline={filteredBaseline}
                            entrepeneurs={entrepeneurs}
                            women={women}
                            editions={editionsCount}
                            activeEntrepeneurs={activeEntrepeneurs}
                            youngEntrepeneurs={youngEntrepeneurs}
                            fields={fields}
                        />
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={1}
                    >
                        <ShortTerm
                            baseline={filteredBaseline}
                            final={final}
                        />
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={2}
                    >
                        {
                            Number(typeId) === TYPE_MBA_STUDENT && <MidTerm
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                        {
                            Number(typeId) === TYPE_ACCELERATOR && <AcceleratorMidTerm
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={3}
                    >
                        {
                            Number(typeId) === TYPE_MBA_STUDENT && <LongTerm
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                        {
                            Number(typeId) === TYPE_ACCELERATOR && <AcceleratorLongTerm
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={4}
                    >
                        {
                            Number(typeId) === TYPE_MBA_STUDENT && <Impact
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                        {
                            Number(typeId) === TYPE_ACCELERATOR && <AcceleratorImpact
                                baseline={filteredBaseline}
                                final={final}
                            />
                        }
                    </TabPanel>
                </ContainerProvider>
            )}
            {loading && (
                <div className='loader-container'>
                    <Loader size={60}/>
                </div>
            )}
        </div>
    );
};

export default E4ImpactMBA;
